import $ from 'jquery';
import * as Constants from '../utils/Constants';
import { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import colors from '../utils/style/colors'
import { SurveyContext } from '../utils/context'
import { useNavigate } from "react-router-dom";
import { StyledLink } from '../utils/style/Atoms'
import axios from "axios"
import Header from '../components/Header'

const ActivityWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: left;
	align-items: center;
	padding: 1em 1em;
	background-color: ${colors.backgroundDark};
`
const ActivityDetails = styled.div`
	display: flex;
	flex-direction: column;
	color: white;
	width: 100%;
`
const Title = styled.h1`
	margin: 0.1em;
	font-size: 1em;
	text-align: left;
`
const ErrorTitle = styled.h1`
	font-size: 2em;
	margin: 0;
	color: #b33232;
	font-weight: 600;
	background-color: #74992e;
`
const Location = styled.span`
	color: ${colors.secondary};
	text-align: left;
`
const TextFullSize = styled.span`
	color: ${colors.secondary};
	text-align: left;
	width: 100%;
`
const TitleWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`

function Review() {
	const { id: queryId } = useParams();
	const { saveAnswers, answers } = useContext(SurveyContext);
	const [error, setError] = useState('');
	const [reviewData, set_review_data] = useState({});
	const navigate = useNavigate();
	useEffect(() => {
		if (!answers[33]) {
			if (!localStorage.getItem('token')) { navigate('/login'); return; }
			answers[32] = JSON.parse(localStorage.getItem('user'));
			answers[33] = localStorage.getItem('token');
			console.log("localStorage user : ", answers[32]);
			console.log("localStorage token : ", answers[33]);
		}

		axios.get(`${Constants.BACKEND_URL}review/${queryId}`,
			{ headers: { Authorization: `Bearer ${answers[33]}` } })
			.then(response => {
				console.log("get review : ", response.data);
				set_review_data(response.data);
			}).catch(error => {
				if (error.response.status === 401) { navigate('/login'); return; }
				console.error(error);
			});

	}, [])

	function save() {
		console.log("save ");

		var review = {
			"_id": queryId,
			"rating": $('#rating').val(),
			"comment": $('#comment').val()
		};
		console.log("save : ", review);

		axios.put(`${Constants.BACKEND_URL}review`, review,
			{ headers: { Authorization: `Bearer ${answers[33]}` } })
			.then(response => {
				console.log("script put axiazdazdos");
				console.log(response.data);
				navigate(`/activity/${reviewData.activityId}`); return;
			}).catch(error => console.error(error));
	}

	function cancel() {
		//navigate(`/activity/${reviewData.activityId}`); return; // does not work but why ??
		axios.get(`${Constants.BACKEND_URL}review/${queryId}`,
			{ headers: { Authorization: `Bearer ${answers[33]}` } })
			.then(response => {
				navigate(`/activity/${reviewData.activityId}`); return;
			}).catch(error => {
				navigate(`/activity/${reviewData.activityId}`); return;
			});
	}

	return (
		<div>
			<Header notif_count={0} />
			<ActivityWrapper>
				<ActivityDetails>
					<TitleWrapper>
						<Location><StyledLink $isFullLink onClick={() => save()}>Sauvegarder / Publier</StyledLink></Location>
						<Location>&nbsp;</Location><Location>&nbsp;</Location>
						<Location><StyledLink $isFullLink onClick={() => cancel(false)}>Annuler</StyledLink></Location>
					</TitleWrapper>
					<TitleWrapper>
						<ErrorTitle>{error ? ("Erreur : " + error) : ("")}</ErrorTitle>&nbsp;
					</TitleWrapper>

					<TitleWrapper>
						<Title>Note &nbsp;&nbsp;&nbsp;</Title>
						<Location>
							<select name="rating" id="rating" >
								<option value="0" selected={reviewData.rating == "0"} >0</option>
								<option value="1" selected={reviewData.rating == "1"}>1</option>
								<option value="2" selected={reviewData.rating == "2"}>2</option>
								<option value="3" selected={reviewData.rating == "3"}>3</option>
								<option value="4" selected={reviewData.rating == "4"}>4</option>
								<option value="5" selected={reviewData.rating == "5"}>5</option>
							</select>
						</Location>
					</TitleWrapper>

					<TitleWrapper>
						<TextFullSize><textarea id="comment" placeholder="Commentaire affiché publiquement" defaultValue={reviewData.comment}
							rows={12} style={{ width: '98%' }} maxLength={10000} wrap="soft" />
						</TextFullSize>
					</TitleWrapper>


				</ActivityDetails>
			</ActivityWrapper>
		</div>
	)
}

export default Review
