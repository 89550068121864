import * as Constants from '../utils/Constants';
import { useState, useEffect, useContext } from 'react';
import NotifCard from '../components/NotifCard'
import styled from 'styled-components'
import colors from '../utils/style/colors'
import { SurveyContext } from '../utils/context'
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom'
import { Loader } from '../utils/style/Atoms'
import { StyledLink } from '../utils/style/Atoms'
import axios from "axios"
import Header from '../components/Header'

const CardsContainer = styled.div`
	display: grid;
	gap: 0px;
	//grid-template-rows: 50px 20px;
	grid-template-columns: repeat(1, 1fr);
	align-items: center;
	justify-items: left;
`
const ActivityContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
`
const ActivityWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: left;
	align-items: center;
	padding: 0px 0;
	margin: 0 0px;
	background-color: ${colors.backgroundDark};
`
const LoaderWrapper = styled.div`
	display: flex;
	justify-content: center;
`
const PageTitle = styled.h1`
	font-size: 30px;
	text-align: center;
	padding-bottom: 30px;
	color: #ffffff;
`

function Notifications() {
	const { save_answers, answers } = useContext(SurveyContext);
	const [notifications, set_notifications] = useState({});
	const [notif_count, set_notif_count] = useState(0);
	const [isLoading, setLoading] = useState(null);
	const navigate = useNavigate();

	useEffect(() => {
		if (!answers[33]) {
			if (!localStorage.getItem('token')) { navigate('/login'); return; }
			answers[32] = JSON.parse(localStorage.getItem('user'));
			answers[33] = localStorage.getItem('token');
			console.log("localStorage user : ", answers[32]);
			console.log("localStorage token : ", answers[33]);
		}
		setLoading('Chargement en cours...');
		get_notifications();
		axios.get(`${Constants.BACKEND_URL}notification/count`,
			{ headers: { Authorization: `Bearer ${answers[33]}` } })
			.then(response => {
				console.log("notification  count : ", response.data);
				set_notif_count(response.data);
			}).catch(error => {
				console.error("get activities error : ", error);
			});
	}, [])

	function get_notifications() {
		console.log("get_notification");
		axios.get(`${Constants.BACKEND_URL}notification`,
			{ headers: { Authorization: `Bearer ${answers[33]}` } })
			.then(response => {
				console.log("get_notification : ", response.data);
				set_notifications(response.data);
				setLoading(false);
			}).catch(error => {
				setLoading(false);
				if (error.response.status === 401) { navigate('/login'); return; }
				console.error(error);
			});
	}

	function set_do_not_send_mail(do_not_send_mail) {
		if (do_not_send_mail && !window.confirm("Désactiver l'envoie de mail ? Vous risquez de manquer les notifications importantes ! :'( ")) {
			return;
		}
		var user = {
			"do_not_send_mail": do_not_send_mail
		};
		console.log("set_do_not_send_mail : ", user);
		axios.put(`${Constants.BACKEND_URL}user/set_do_not_send_mail`, user,
			{ headers: { Authorization: `Bearer ${answers[33]}` } })
			.then(response => {
				console.log("script put axiazdazdos");
				console.log(response.data);
				answers[32].do_not_send_mail = do_not_send_mail;
				localStorage.setItem('user', JSON.stringify(answers[32]));
				window.location.reload();
			}).catch(error => console.error(error));
	}

	return (
		<div>
			<Header notif_count={notif_count} />
			{answers[32] && answers[32].do_not_send_mail && (
				<ActivityWrapper>
					<StyledLink $isFullLink onClick={() => set_do_not_send_mail(false)}>Activer l'envoi de mail</StyledLink>
				</ActivityWrapper>
			)}{answers[32] && answers[32].do_not_send_mail && (<div>&nbsp;</div>)}
			<ActivityContainer>
				{isLoading ? (<LoaderWrapper><Loader data-testid="loader" /></LoaderWrapper>) : ("")}
				<ActivityWrapper>
					<CardsContainer>
						{Array.isArray(notifications) && (
							notifications?.map((msg) => (
								("privateInvite" === msg.notif_type) ? (
									<Link key={msg._id} to={`/user/${msg.userId}`}>
										<NotifCard
											content={msg.user_title + " vous a envoyé une demande de contact"}
											created={msg.created}
											user_image={`${Constants.BACKEND_URL}images/${msg.user_image}`}
											seen={msg.seen.toString()}
										/>
									</Link>
								) :
								("privateInviteAccept" === msg.notif_type) ? (
									<Link key={msg._id} to={`/user/${msg.userId}`}>
										<NotifCard
											content={msg.user_title + " a accepté votre demande de contact"}
											created={msg.created}
											user_image={`${Constants.BACKEND_URL}images/${msg.user_image}`}
											seen={msg.seen.toString()}
										/>
									</Link>
								) :
								("welcome" === msg.notif_type) ? (
									<Link key={msg._id} to={`/user/${msg.userId}`}>
										<NotifCard
											content={"Bienvenue sur sorties-toulouse.fr"}
											created={msg.created}
											user_image={`${Constants.BACKEND_URL}images/${msg.user_image}`}
											seen={msg.seen.toString()}
										/>
									</Link>
								) :
								("visit" === msg.notif_type) ? (
									<Link key={msg._id} to={`/user/${msg.userId}`}>
										<NotifCard
											content={msg.user_title + " a visité votre profil"}
											created={msg.created}
											user_image={`${Constants.BACKEND_URL}images/${msg.user_image}`}
											seen={msg.seen.toString()}
										/>
									</Link>
								) :
								("private_message" === msg.notif_type) ? (
									<Link key={msg._id} to={`/user/${msg.userId}`}>
										<NotifCard
											content={msg.user_title + " : " + msg.content}
											created={msg.created}
											user_image={`${Constants.BACKEND_URL}images/${msg.user_image}`}
											seen={msg.seen.toString()}
										/>
									</Link>
								) :
								("user_registered_your_activity" === msg.notif_type) ? (
									<Link key={msg._id} to={`/activity/${msg.activityId}`}>
										<NotifCard
											content={msg.user_title + " s'est inscrit à votre sortie " + msg.activity_title}
											created={msg.created}
											user_image={`${Constants.BACKEND_URL}images/${msg.activity_image}`}
											seen={msg.seen.toString()}
										/>
									</Link>
								) : ("user_waitlist_in_your_activity" === msg.notif_type) ? (
									<Link key={msg._id} to={`/activity/${msg.activityId}`}>
										<NotifCard
											content={msg.user_title + " est en attente de validation à votre sortie " + msg.activity_title}
											created={msg.created}
											user_image={`${Constants.BACKEND_URL}images/${msg.activity_image}`}
											seen={msg.seen.toString()}
										/>
									</Link>
								) : ("activity_accept" === msg.notif_type) ? (
									<Link key={msg._id} to={`/activity/${msg.activityId}`}>
										<NotifCard
											content={msg.user_title + " a accepté votre participation à la sortie " + msg.activity_title}
											created={msg.created}
											user_image={`${Constants.BACKEND_URL}images/${msg.activity_image}`}
											seen={msg.seen.toString()}
										/>
									</Link>
								) : ("visit_activity" === msg.notif_type) ? (
									<Link key={msg._id} to={`/activity/${msg.activityId}`}>
										<NotifCard
											content={msg.user_title + " a visité votre sortie " + msg.activity_title}
											created={msg.created}
											user_image={`${Constants.BACKEND_URL}images/${msg.activity_image}`}
											seen={msg.seen.toString()}
										/>
									</Link>
								) : ("review_participant_created" === msg.notif_type) ? (
									<Link key={msg._id} to={`/activity/${msg.activityId}`}>
										<NotifCard
											content={"Vous pouvez laisser un avis sur votre expérience vécue lors de la sortie " + msg.activity_title}
											created={msg.created}
											user_image={`${Constants.BACKEND_URL}images/${msg.activity_image}`}
											seen={msg.seen.toString()}
										/>
									</Link>
								) : ("review_organizer_created" === msg.notif_type) ? (
									<Link key={msg._id} to={`/activity/${msg.activityId}`}>
										<NotifCard
											content={"Vous pouvez laisser des avis sur votre expérience vécue lors de la sortie " + msg.activity_title}
											created={msg.created}
											user_image={`${Constants.BACKEND_URL}images/${msg.activity_image}`}
											seen={msg.seen.toString()}
										/>
									</Link>
								) : ("modified_activity" === msg.notif_type) ? (
									<Link key={msg._id} to={`/activity/${msg.activityId}`}>
										<NotifCard
											content={"La sortie " + msg.activity_title + " a été modifiée"}
											created={msg.created}
											user_image={`${Constants.BACKEND_URL}images/${msg.activity_image}`}
											seen={msg.seen.toString()}
										/>
									</Link>
								) : ("")
							)))}
					</CardsContainer>
				</ActivityWrapper>
				<div>&nbsp;</div>
				{answers[32] && !answers[32].do_not_send_mail && (
					<ActivityWrapper>
						<StyledLink $isFullLink onClick={() => set_do_not_send_mail(true)}>Désactiver l'envoi de mail</StyledLink>
					</ActivityWrapper>
				)}
				<PageTitle>{isLoading && <p>{isLoading}</p>}</PageTitle>

			</ActivityContainer>
		</div>
	)
}

export default Notifications
