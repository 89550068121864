import * as Constants from '../utils/Constants';
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import UserCard from '../components/UserCard'
import colors from '../utils/style/colors'
import { Loader } from '../utils/style/Atoms'
import { useState, useEffect, useContext } from 'react';
import { SurveyContext } from '../utils/context'
import { useNavigate } from "react-router-dom";
import axios from "axios"
import Header from '../components/Header'

const CardsContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	align-items: center;
	justify-items: center;
`
const PageTitle = styled.h1`
	font-size: 30px;
	text-align: center;
	padding-bottom: 30px;
	color: #ffffff;
`
const PageSubtitle = styled.h2`
	font-size: 20px;
	color: ${colors.secondary};
	font-weight: 300;
	text-align: center;
	padding-bottom: 30px;
	color: #ffffff;
`
const LoaderWrapper = styled.div`
	display: flex;
	justify-content: center;
`

function Users() {
	const [activityArray, setStuffArray] = useState(null);
	const [isLoading, setLoading] = useState(null);
	const [error, setError] = useState(null);
	const [user_title, set_user_title] = useState(null);
	const [notif_count, set_notif_count] = useState(0);
	const { answers } = useContext(SurveyContext)
	console.log("isLoading = ", isLoading)
	//console.log("answers[32] ", answers[32]);
	//console.log("answers[33] ", answers[33]);
	const navigate = useNavigate();

	useEffect(() => {
		if (!answers[33]) {
			if (!localStorage.getItem('token')) { navigate('/login'); return; }
			answers[32] = JSON.parse(localStorage.getItem('user'));
			answers[33] = localStorage.getItem('token');
			console.log("localStorage user : ", answers[32]);
			console.log("localStorage token : ", answers[33]);
		}
		set_user_title(answers[32].title);
		setLoading('Chargement en cours...');
		const source = axios.CancelToken.source();
		console.log("gets all axios");
		axios.get(`${Constants.BACKEND_URL}user`,
			{ cancelToken: source.token, headers: { Authorization: `Bearer ${answers[33]}` } })
			.then(response => {
				setLoading(false);
				console.log("responseezf : ", response);
				setStuffArray(response.data);
			}).catch(error => {
				setLoading(false);
				if (error.response.status === 401) { navigate('/login'); return; }
				console.error("get users error : ", error);
				setError(JSON.stringify(error));
			});
		axios.get(`${Constants.BACKEND_URL}notification/count`,
			{ headers: { Authorization: `Bearer ${answers[33]}` } })
			.then(response => {
				console.log("notification  count : ", response.data);
				set_notif_count(response.data);
			}).catch(error => {
				console.error("get activities error : ", error);
			});
		return () => {
			source.cancel();
		}
	}, [])

	function getAge(dateString) {
		if (!dateString) {
			return dateString;
		}
		var today = new Date();
		var birthDate = new Date(dateString);
		var age = today.getFullYear() - birthDate.getFullYear();
		var m = today.getMonth() - birthDate.getMonth();
		if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
			age--;
		}
		return age;
	}

	return (
		<div>
			<Header notif_count={notif_count} />
			{activityArray ? (
				<div style={{ backgroundColor: '#434654', width: '100%' }}>{activityArray.length}&nbsp;Utilisateurs listés</div>
			) : ("")}
			{isLoading ? (
				<LoaderWrapper>
					<Loader data-testid="loader" />
				</LoaderWrapper>
			) : (
				<CardsContainer>
					{activityArray?.map((user) => (
						<Link key={`user-${user._id}`} to={`/user/${user._id}`}>
							<UserCard
								title={user.title}
								birthday={getAge(user.birthday)}
								picture={`${Constants.BACKEND_URL}images/${user.image}`}
							/>
						</Link>
					))}
				</CardsContainer>
			)}
			<PageTitle>
				{isLoading && <p>{isLoading}</p>}
			</PageTitle>
			<PageSubtitle>
				{error && <p>{error}</p>}
			</PageSubtitle>
		</div>
	)
}

export default Users
