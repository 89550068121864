import $ from 'jquery';
import * as Constants from '../utils/Constants';
import { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom'
import UserSmallCard from '../components/UserSmallCard'
import MessageCard from '../components/MessageCard'
import ReviewCard from '../components/ReviewCard'
import CreatorReviewCard from '../components/CreatorReviewCard'
import { StyledLink } from '../utils/style/Atoms'
import { SmallStyledLink } from '../utils/style/Atoms'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import colors from '../utils/style/colors'
import { SurveyContext } from '../utils/context'
import { useNavigate } from "react-router-dom";
import { Loader } from '../utils/style/Atoms'
import Header from '../components/Header'
import dateFormat from 'dateformat';
import axios from "axios"
import Linkify from 'react-linkify';

const LoaderWrapper = styled.div`
	display: flex;
	justify-content: center;
`

const Title = styled.h1`
	margin: 0.1em;
	font-size: 1.2em;
`
const DateLine = styled.h1`
	margin: 0.1em;
	font-size: 1em;
	color: #f5e4c7;
`
const Description = styled.h1`
	margin: 0.1em;
	font-size: 0.8em;
	text-align: left;
`
const TextLabels = styled.div`
	font-size: 0.8em;
`
const Organisateur = styled.div`
	font-size: 1em;
	color: #b9f4f3;
`
const Details = styled.h1`
	margin: 0.1em;
	font-size: 0.8em;
	color: #f5e4c7;
`
const CardsContainer = styled.div`
	display: grid;
	gap: 0px;
	//grid-template-rows: 50px 20px;
	grid-template-columns: repeat(1, 1fr);
	align-items: center;
	justify-items: left;
`
const TopContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`
const ErrorTitle = styled.h1`
	font-size: 30px;
	margin: 0;
	color: #b33232;
	font-weight: 600;
	background-color: #74992e;
`
const ActivityWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: left;
	align-items: center;
	padding: 0px 0;
	margin: 0 0px;
	background-color: ${colors.backgroundDark};
`
const UserListWrapper = styled.div`
	display: grid;
	gap: 0px;
	//grid-template-rows: 50px 20px;
	grid-template-columns: repeat(5, 1fr);
	width: 100%;
	background-color: ${colors.backgroundDark};
`

const VerticalWrapper = styled.div`
	margin-top: 0.3em;
	margin-left: 0.5em;
	display: flex;
	flex-direction: column;
`
const HorizontalWrapper = styled.div`
	display: flex;
	flex-direction: line;
	margin-top: 0.8em;
	margin-bottom: 0.5em;
`
const HorizontalWrapperNoMargin = styled.div`
	display: flex;
	flex-direction: line;
`
const ActivityDetails = styled.div`
	display: flex;
	flex-direction: column;
	margin-left: 0.5em;
	color: white;
	width: 100%;
`
const PictureAndButtonHeader = styled.div`
	display: flex;
	flex-direction: line;
	margin-left: 1em;
	color: white;
`
const Picture = styled.img`
	height: 7em;
	width: 7em;
	border-radius: 25px;
`
const Location = styled.span`
	color: ${colors.secondary};
`
const InputMessage = styled.span`
	width: 100%;
`
const MessagesWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: left;
	align-items: center;
	padding: 0px 0;
	margin: 0 0px;
	background-color: ${colors.backgroundDark};
`
const TitleWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`
const Availability = styled.span`
	&:before {
		position: absolute;
		left: 0;
		top: 4px;
		height: 10px;
		width: 10px;
		border-radius: 5px;
		background-color: ${({ available }) => (available ? 'green' : 'red')};
		content: '';
	}
	padding-left: 20px;
	position: relative;
`
function Activity() {
	const { id: queryId } = useParams();
	const [notif_count, set_notif_count] = useState(0);
	const { save_answers, answers } = useContext(SurveyContext);
	const [error, setError] = useState('');
	const [activityData, set_activity_data] = useState({});
	const [edit_page, set_edit_page] = useState(false);
	const [activity_is_Date_In_Past, set_activity_is_Date_In_Past] = useState('');
	const [activity_date, set_activity_date] = useState('');
	const [activity_date_string, set_activity_date_string] = useState('');
	const [activityUserLinkData, set_activity_user_link_data] = useState({});
	const [activityWaitlistUserLinkData, set_activity_waitlist_user_link_data] = useState({});
	const [activity_messages, set_activity_messages] = useState({});
	const [review_messages, set_review_messages] = useState({});
	const [participant, set_participant] = useState(false);
	const [participant_or_waitlist, set_participant_or_waitlist] = useState(false);
	const [creator, set_creator] = useState(false);
	const [inputSelectedFile, setInputSelectFile] = useState('')
	const [isLoading, setLoading] = useState(null);
	const navigate = useNavigate();

	useEffect(() => {
		if (!answers[33]) {
			if (!localStorage.getItem('token')) { navigate('/login'); answers[34] = "/activity/" + queryId; return; }
			answers[32] = JSON.parse(localStorage.getItem('user'));
			answers[33] = localStorage.getItem('token');
			console.log("localStorage user : ", answers[32]);
			console.log("localStorage token : ", answers[33]);
		}
		get_activity_data();
		axios.get(`${Constants.BACKEND_URL}notification/count`,
			{ headers: { Authorization: `Bearer ${answers[33]}` } })
			.then(response => {
				console.log("notification  count : ", response.data);
				set_notif_count(response.data);
			}).catch(error => {
				console.error("get activities error : ", error);
			});
	}, [])

	function get_activity_data() {
		console.log("get one Activity " + queryId);
		axios.get(`${Constants.BACKEND_URL}activity/${queryId}`,
			{ headers: { Authorization: `Bearer ${answers[33]}` } })
			.then(response => {
				console.log("get Activity : ", response.data);
				set_activity_data(response.data);
				const localDate = new Date(response.data.date);
				// Optionally remove second/millisecond if needed
				localDate.setSeconds(null);
				localDate.setMilliseconds(null);
				set_activity_date(localDate.toISOString().slice(0, -1));

				var activity_date_str = " " + response.data.date;
				try {
					activity_date_str = dateFormat(localDate, "dddd d mmm yyyy - HH:MM");
				} catch (error) {
					console.error("activity_date_str dateFormat error : %o", error);
				}
				console.log("activity_date_string = ", activity_date_str);
				set_activity_date_string(activity_date_str);

				set_activity_is_Date_In_Past(new Date() - localDate > 0);
				//if (activity_is_Date_In_Past) { // todo commented for test
				get_review_messages();
				//}

				get_activity_user_link_data();
			}).catch(error => {
				if (error.response.status === 401) { navigate('/login'); return; }
				console.error(error);
			});
	}

	function get_activity_user_link_data() {
		axios.get(`${Constants.BACKEND_URL}activity_user_link/activity/${queryId}`,
			{ headers: { Authorization: `Bearer ${answers[33]}` } })
			.then(response => {
				console.log("get activityUserLinkData : ", response.data);

				var activity_user_link_array = [];
				var activity_waitlist_user_link_array = [];
				for (var key in response.data) {
					var obj = response.data[key];
					console.log("get activityUserLinkData  obj: ", obj);
					if (obj.status === 'waitlist') {
						console.log("get activityUserLinkData  waitlist obj: ", obj);
						activity_waitlist_user_link_array.push(obj);
					} else {
						activity_user_link_array.push(obj);
					}
				}

				set_activity_user_link_data(activity_user_link_array);
				set_activity_waitlist_user_link_data(activity_waitlist_user_link_array);

				if (Array.isArray(response.data)) {
					console.log("get TODODZE : ", response.data);
					var participantItemFound = response.data.find(item => {
						return (item.userId === answers[32]._id && (item.status === "creator" || item.status === "accepted"))
					});
					if (participantItemFound !== undefined) {
						console.log("get participantItemFound : ", participantItemFound);
						set_participant(true);
						set_creator(participantItemFound.status === "creator");
						get_activity_messages();
					}
					var participant_or_waitlist_ItemFound = response.data.find(item => {
						return (item.userId === answers[32]._id)
					});
					if (participant_or_waitlist_ItemFound !== undefined) {
						console.log("get participant_or_waitlist_ItemFound : ", participant_or_waitlist_ItemFound);
						set_participant_or_waitlist(true);
					}
				}
			}).catch(error => console.error(error));
	}

	function get_activity_messages() {
		axios.get(`${Constants.BACKEND_URL}message/${queryId}`,
			{ headers: { Authorization: `Bearer ${answers[33]}` } })
			.then(response => {
				console.log("get_activity_messages : ", response.data);
				set_activity_messages(response.data)
			}).catch(error => console.error(error));
	}

	function get_review_messages() {
		axios.get(`${Constants.BACKEND_URL}review/activity/${queryId}`,
			{ headers: { Authorization: `Bearer ${answers[33]}` } })
			.then(response => {
				console.log("get_review_messages : ", response.data);
				set_review_messages(response.data)
			}).catch(error => console.error(error));
	}

	function register_to_activity() {
		console.log("answers[33] : ", answers[33]);
		console.log("answers[32] : ", answers[32]);
		axios.post(`${Constants.BACKEND_URL}activity_user_link`, {
			"activityId": queryId,
			"activity_userId": activityData.userId,
			"activity_title": activityData.title,
			"activity_image": activityData.image,
			"activity_city": activityData.city,
			"activity_date": activityData.date
		}, { headers: { Authorization: `Bearer ${answers[33]}` } })
			.then(response => {
				console.log("register_to_activity : ", response.data);
				get_activity_data();
			}).catch(error => console.error(error));
	}

	function unregister_to_activity() {
		console.log("unregister_to_activity answers[33] : " + answers[33]);
		if (window.confirm("Annuler l'inscription ?")) {
			axios.delete(`${Constants.BACKEND_URL}activity_user_link/${queryId}`,
				{ headers: { Authorization: `Bearer ${answers[33]}` } })
				.then(response => {
					console.log("unregister_to_activity : ", response.data);
					window.location.reload();
				}).catch(error => console.error(error));
		}
	}

	function validate_form() {
		console.log("script axios answers[33] : " + answers[33]);
		if ("" === $('#title').val()) {
			setError("Titre obligatoire !");
			return false;
		}
		if ("" === $('#date').val()) {
			setError("Date obligatoire !");
			return false;
		}
		return true;
	}

	function delete_activity() {
		console.log("delete_activity answers[33] : " + answers[33]);
		if (window.confirm("Supprimer la sortie ? ATTENTION cette action est irréversible !")) {
			axios.delete(`${Constants.BACKEND_URL}activity/${queryId}`,
				{ headers: { Authorization: `Bearer ${answers[33]}` } })
				.then(response => {
					console.log("delete_activity : ", response.data);
					navigate('/'); return;
				}).catch(error => console.error(error));
		}
	}

	function toIsoString(date) {
		// source https://stackoverflow.com/questions/17415579/how-to-iso-8601-format-a-date-with-timezone-offset-in-javascript
		// 2024-07-28T18:14:11+02:00					activity_date recu dans ce format string : 2024-07-27T04:02:00.000
		// new Date().toUTCString() = Sun, 28 Jul 2024 16:15:52 GMT
		// 			.toISOString() = 2024-07-28T16:16:02.416Z
		var tzo = -date.getTimezoneOffset(),
			// dif = tzo >= 0 ? '+' : '-',
			pad = function (num) {
				return (num < 10 ? '0' : '') + num;
			};
		return date.getFullYear() +
			'-' + pad(date.getMonth() + 1) +
			'-' + pad(date.getDate()) +
			'T' + pad(date.getHours() + Math.floor(Math.abs(tzo) / 60)) +		// mon fix
			':' + pad(date.getMinutes()) +
			':' + pad(date.getSeconds()) /*+
			dif + pad(Math.floor(Math.abs(tzo) / 60)) +				// 2024-07-28T18:14:11+02:00		si on ajoute cette partie, 2024-07-28T18:24:54 sans cette partie
			':' + pad(Math.abs(tzo) % 60)*/;
	}

	function save() {
		console.log("inputSelectedFile : ", inputSelectedFile);
		console.log("----------------------------- ");
		Date.prototype.addHours = function (h) {
			this.setTime(this.getTime() + (h * 60 * 60 * 1000));
			return this;
		}
		console.log("$('#date').val() : ", $('#date').val());
		const date_fix_hour = toIsoString(new Date($('#date').val()).addHours(-2)) + "Z"; // Date parsing already add 2 hours, so we have to remove 4 hours in summer, and 2 in winter !...
		// https://stackoverflow.com/questions/5619202/parsing-a-string-to-a-date-in-javascript
		// Best practice  = always store and make computations as UTC
		// To parse a date as UTC, append a Z - e.g.: new Date('2011-04-11T10:20:30Z')
		console.log("----------------------------- ");

		setLoading(true);
		var activity = {
			"_id": queryId,
			"title": $('#title').val(),
			"description": $('#description').val(),
			"private_description": $('#private_description').val(),
			"city": $('#city').val(),
			"price": $('#price').val(),
			"date": date_fix_hour,
			"duration": $('#duration').val(),
			"max_user_number": $('#max_user_number').val(),
			"additional_user_number": $('#additional_user_number').val(),
			"additional_user_surname": $('#additional_user_surname').val(),
			"address_location": $('#address_location').val(),
			"meet_address_location": $('#meet_address_location').val(),
			"access": $('#access').val(),
			"validation": $('#validation').val()
		};
		console.log("create_activity : ", activity);
		if (!validate_form()) { return; }
		const formData = new FormData();
		formData.append('image', inputSelectedFile[0]);
		formData.append('activity', JSON.stringify(activity));
		axios.put(`${Constants.BACKEND_URL}activity`, formData,
			{ headers: { Authorization: `Bearer ${answers[33]}`, 'Content-Type': 'multipart/form-data' } })
			.then(response => {
				console.log("save activity response : ", response.data);
				if (window.confirm("Notifier les participants de la modification ?")) {
					axios.get(`${Constants.BACKEND_URL}activity/notify/${queryId}`,
						{ headers: { Authorization: `Bearer ${answers[33]}` } })
						.then(response => {
							console.log("activity notify: ", response.data);
						}).catch(error => console.error(error));
				}
				setLoading(true);
				window.location.reload();
			}).catch(error => console.error(error));
	}

	function cancel() {
		if (window.confirm("Annuler les modifications ?")) {
			set_edit_page(false);
		}
	}

	function validate_user(activity_user_link_id) {
		console.log("validate_user : ", activity_user_link_id);
		axios.get(`${Constants.BACKEND_URL}activity_user_link/accept/${activity_user_link_id}`,
			{ headers: { Authorization: `Bearer ${answers[33]}` } })
			.then(response => {
				console.log("validate_user activity response : ", response.data);
				window.location.reload();
			}).catch(error => console.error(error));
	}

	var { image, userId, current_user_number, max_user_number } = activityData

	console.log("participant : ", participant);
	console.log("creator : ", creator);

	function send_message() {
		axios.post(`${Constants.BACKEND_URL}message`, {
			"activityId": queryId,
			"content": $('#message').val()
		}, { headers: { Authorization: `Bearer ${answers[33]}` } })
			.then(response => {
				console.log("send message response : ", response.data);
				get_activity_data();
			}).catch(error => console.error(error));
		$('#message').val("");
	}

	function duplicate_activity() {
		if (window.confirm("Dupliquer la sortie ?")) {
			// Copy object to allow delete object private properties
			console.log("create_activity : ", activityData);
			const activityDuplicate = JSON.parse(JSON.stringify(activityData));
			delete activityDuplicate.created;
			delete activityDuplicate.modified;
			delete activityDuplicate.userId;
			delete activityDuplicate.user_city;
			delete activityDuplicate.user_image;
			delete activityDuplicate.user_title;
			delete activityDuplicate.view_count;
			delete activityDuplicate.score;
			delete activityDuplicate.current_user_number;
			delete activityDuplicate.__v;
			delete activityDuplicate._id;

			Date.prototype.addHours = function (h) {
				this.setTime(this.getTime() + (h * 60 * 60 * 1000));
				return this;
			}
			const date_plus_one_hour = new Date().addHours(1);
			console.log("date_plus_one_day : ", date_plus_one_hour);

			activityDuplicate.date = date_plus_one_hour;
			activityDuplicate.title = "DUPLIQUEE : " + activityDuplicate.title;

			console.log("create_activity : ", activityDuplicate);
			if (!validate_form()) { return; }

			axios.post(`${Constants.BACKEND_URL}activity`, activityDuplicate,
				{ headers: { Authorization: `Bearer ${answers[33]}` } })
				.then(response => {
					console.log("script post axiazdazdos");
					console.log(response.data);
					if (response.data._id) {
						navigate('/'); return;
					}
				}).catch(error => console.error(error));
		}
	}

	function edit_review(review_id) {
		console.log("edit_review id : " + review_id);
		//navigate('/review/' + review_id); return;; return; // does not work but why ??
		axios.get(`${Constants.BACKEND_URL}review/${queryId}`,
			{ headers: { Authorization: `Bearer ${answers[33]}` } })
			.then(response => {
				navigate('/review/' + review_id); return;
			}).catch(error => {
				navigate('/review/' + review_id); return;
			});

	}

	return (
		<div>
			<Header notif_count={notif_count} />
			<TopContainer>
				<ActivityWrapper>
					<PictureAndButtonHeader>
						<a href={`${Constants.BACKEND_URL}images/${image}`}>
							<Picture src={`${Constants.BACKEND_URL}images/${image}`} alt={userId} height={150} width={150} />
						</a>
						<VerticalWrapper>
							{isLoading ? (<LoaderWrapper><Loader data-testid="loader" /></LoaderWrapper>) : ("")}
							{creator && edit_page && !isLoading && (
								<HorizontalWrapper>
									<Location><StyledLink $isFullLink onClick={() => save()}>Sauvegarder</StyledLink></Location>
									<Location>&nbsp;</Location><Location>&nbsp;</Location>
									<Location><StyledLink $isFullLink onClick={() => cancel()}>Annuler</StyledLink></Location>
								</HorizontalWrapper>
							)}
							<HorizontalWrapper>
								{creator && !edit_page && !activity_is_Date_In_Past && (
									<StyledLink $isFullLink onClick={() => set_edit_page(true)}>Modifier</StyledLink>
								)}
								{creator && !edit_page && (
									<StyledLink $isFullLink onClick={() => duplicate_activity(true)}>Dupliquer</StyledLink>
								)}
								{creator && !edit_page && Array.isArray(activityUserLinkData) && activityUserLinkData.length === 1 && (
									<SmallStyledLink $isFullLink onClick={() => delete_activity(true)}>Supprimer</SmallStyledLink>
								)}
							</HorizontalWrapper>
							{typeof creator == "boolean" && !creator && !activity_is_Date_In_Past && (
								(participant_or_waitlist === false) ? (
									<StyledLink $isFullLink onClick={() => register_to_activity()}>Participer</StyledLink>
								) : (
									<StyledLink $isFullLink onClick={() => unregister_to_activity()}>Annuler l'inscription</StyledLink>
								)
							)}

							{edit_page && <TitleWrapper><Location><input type="file" onChange={(e) => setInputSelectFile(e.target.files)} /></Location><Title>Modifier l'image</Title></TitleWrapper>}

							{edit_page ? (<TitleWrapper>
								<Location><input id="title" style={{ width: '20em' }} placeholder="Titre obligatoire ! " defaultValue={activityData.title} /></Location><Title>Titre*</Title></TitleWrapper>
							) : (<Title>{activityData.title}</Title>)}

							{edit_page ? (<TitleWrapper>
								<Location><input id="date" type="datetime-local" defaultValue={toIsoString(new Date(new Date(activity_date).toISOString()))} /></Location><Title>Date*</Title></TitleWrapper>
							) : (<DateLine>{activity_date_string} &nbsp;&nbsp;&nbsp;{edit_page ? ("") : (<span>{activityData.duration}</span>)}&nbsp;&nbsp;&nbsp;&nbsp;
								{current_user_number} / {max_user_number}&nbsp;<Availability available={"" + (current_user_number === max_user_number)}></Availability>
							</DateLine>)}

							{edit_page ? (<TitleWrapper>
								<Location><input id="duration" defaultValue={activityData.duration} /></Location><Title>Durée</Title></TitleWrapper>
							) : ("")}
							<TitleWrapper><Organisateur>Organisateur&nbsp;:&nbsp;&nbsp;{activityData.user_title}</Organisateur></TitleWrapper>
						</VerticalWrapper>
					</PictureAndButtonHeader>
				</ActivityWrapper>
				<ActivityWrapper>
					<ActivityDetails>

						<TitleWrapper>
							<ErrorTitle>{error && ("Erreur : " + error)}</ErrorTitle>
						</TitleWrapper>

						{edit_page ? (<TitleWrapper>
							<textarea id="description" placeholder="Description affichée publiquement"
								rows={12} style={{ width: '96%' }} maxLength={10000} wrap="soft" defaultValue={activityData.description} /></TitleWrapper>
						) : (
							<Description><Linkify><div style={{ whiteSpace: "pre-wrap" }}>{activityData.description}</div></Linkify></Description>
						)}

						{edit_page ? (<TitleWrapper>
							<textarea id="private_description" placeholder="Description visible seulement par les participants"
								rows={12} style={{ width: '96%' }} maxLength={10000} wrap="soft" defaultValue={activityData.private_description} /></TitleWrapper>
						) : (
							<Description><Linkify><div style={{ whiteSpace: "pre-wrap" }}>{activityData.private_description}</div></Linkify></Description>
						)}

						{edit_page ? (<TitleWrapper>
							<Location><input id="city" defaultValue={activityData.city} /></Location><Title>Ville</Title></TitleWrapper>
						) : (<HorizontalWrapperNoMargin><Details>Ville&nbsp;:&nbsp;&nbsp;</Details><TextLabels>{activityData.city}</TextLabels></HorizontalWrapperNoMargin>)}

						{edit_page ? (<TitleWrapper>
							<textarea id="address_location" defaultValue={activityData.address_location} placeholder="Lieu affiché publiquement"
								rows={3} style={{ width: '96%' }} maxLength={3000} wrap="soft" /></TitleWrapper>
						) : (<HorizontalWrapperNoMargin><Details>Lieu&nbsp;:&nbsp;&nbsp;</Details>
							<Linkify><div style={{ whiteSpace: "pre-wrap" }}>{activityData.address_location}</div></Linkify></HorizontalWrapperNoMargin>
						)}

						{edit_page ? (<TitleWrapper>
							<textarea id="meet_address_location" defaultValue={activityData.meet_address_location} placeholder="Lieu de RDV visible seulement par les participants"
								rows={3} style={{ width: '96%' }} maxLength={3000} wrap="soft" /></TitleWrapper>
						) : (participant ? (
							<HorizontalWrapperNoMargin><Details>Lieu de RDV&nbsp;:&nbsp;&nbsp;</Details>
								<Linkify><div style={{ whiteSpace: "pre-wrap" }}>{activityData.meet_address_location}</div></Linkify></HorizontalWrapperNoMargin>) : ("")
						)}

						{edit_page && (<TitleWrapper>
							<Location><input id="max_user_number" type="number" defaultValue={activityData.max_user_number} /></Location><Title>Nombre maximun de participants</Title></TitleWrapper>
						)}

						{edit_page && (<TitleWrapper>
							<Location><input id="additional_user_number" type="number" defaultValue={activityData.additional_user_number} /></Location><Title>Nombre de participants additionnels</Title></TitleWrapper>
						)}
						{edit_page && (
							<input id="additional_user_surname" defaultValue={activityData.additional_user_surname} placeholder="Prénoms des participants additionnels"
								style={{ width: '96%' }} />
						)}

						{!edit_page && activityData.additional_user_number !== 0 && (<HorizontalWrapperNoMargin><Details>Nombre de participants additionnels&nbsp;:&nbsp;&nbsp;</Details>
							<Linkify><div style={{ whiteSpace: "pre-wrap" }}>{activityData.additional_user_number}</div></Linkify></HorizontalWrapperNoMargin>
						)}
						{!edit_page && activityData.additional_user_number !== 0 && (<HorizontalWrapperNoMargin><Details>Participants additionnels&nbsp;:&nbsp;&nbsp;</Details>
							<Linkify><div style={{ whiteSpace: "pre-wrap" }}>{activityData.additional_user_surname}</div></Linkify></HorizontalWrapperNoMargin>
						)}

						{edit_page ? (<TitleWrapper>
							<Location>
								<select id="access">
									<option value="public" selected={activityData.access === "public"} >Tous les utilisateurs connectés</option>
									<option value="link_only" selected={activityData.access === "link_only"}>Via le lien seulement</option>
								</select>
							</Location><Details>Visibilité</Details></TitleWrapper>
						) : (<TitleWrapper><Details>Visibilité&nbsp;:&nbsp;&nbsp;</Details><TextLabels>
							{"public" === activityData.access ? ("Tous les utilisateurs connectés") : ("")}
							{"link_only" === activityData.access ? ("Via le lien seulement") : ("")}
							{"friends_only" === activityData.access ? ("Amis seulement") : ("")}
							{"private" === activityData.access ? ("Seulement moi") : ("")}</TextLabels>
						</TitleWrapper>)}

						{edit_page ? (<TitleWrapper>
							<Location>
								<select id="validation">
									<option value="automatic" selected={activityData.validation === "automatic"}>Automatique</option>
									<option value="manual" selected={activityData.validation === "manual"}>Manuelle</option>
								</select>
							</Location><Details>Validation des participants</Details></TitleWrapper>
						) : (<TitleWrapper><Details>Validation des participants&nbsp;:&nbsp;&nbsp;</Details><TextLabels>
							{"automatic" === activityData.validation ? ("Automatique") : ("")}
							{"manual" === activityData.validation ? ("Manuelle") : ("")}</TextLabels>
						</TitleWrapper>)}

						{edit_page ? (<TitleWrapper>
							<Location><input id="price" defaultValue={activityData.price} /></Location><Title>Coût</Title></TitleWrapper>
						) : (<HorizontalWrapperNoMargin><Details>Coût&nbsp;:&nbsp;&nbsp;</Details><TextLabels>{activityData.price}</TextLabels></HorizontalWrapperNoMargin>)}

						{activityData.created ? (
							<div>
								<TitleWrapper><Details>Derniere modification &nbsp;:&nbsp;&nbsp;</Details><TextLabels>{dateFormat(new Date(activityData.modified), "d mmm yyyy")}</TextLabels></TitleWrapper>
								<TitleWrapper><Details>Sortie crée le &nbsp;:&nbsp;&nbsp;</Details><TextLabels>{dateFormat(new Date(activityData.created), "d mmm yyyy")}</TextLabels></TitleWrapper>
								<TitleWrapper><Details>Nombre de vue &nbsp;:&nbsp;&nbsp;</Details><TextLabels>{activityData.view_count}</TextLabels></TitleWrapper>
							</div>
						) : ("")}

					</ActivityDetails>
				</ActivityWrapper>

				<ActivityWrapper>
					<CardsContainer>
						{Array.isArray(activity_messages) && (
							activity_messages?.map((msg) => (
								<MessageCard key={`msg-${msg._id}`}
									content={msg.content}
									created={msg.created}
									user_id={msg.userId}
									user_title={msg.user_title}
									user_image={`${Constants.BACKEND_URL}images/${msg.user_image}`}
								/>
							)))}
					</CardsContainer>
				</ActivityWrapper>
				{participant && !edit_page && (
					<MessagesWrapper>
						<StyledLink $isFullLink onClick={() => send_message()}>&gt;</StyledLink>
						<InputMessage>
							<textarea id="message" rows={3} style={{ width: '98%' }} maxLength={1000} wrap="soft"
								placeholder="Ajouter un message visible par les participants seulement" />
						</InputMessage>
					</MessagesWrapper>
				)}
				<ActivityWrapper>
					&nbsp;
				</ActivityWrapper>

				{Array.isArray(activityWaitlistUserLinkData) && activityWaitlistUserLinkData.length > 0 && (
					<div>{activityWaitlistUserLinkData.length}&nbsp;participant(s) en liste d'attente</div>
				)}
				<UserListWrapper>
					{Array.isArray(activityWaitlistUserLinkData) && (
						activityWaitlistUserLinkData?.map((link) => (
							<div>
								<Link key={`user-${link.userId}`} to={`/user/${link.userId}`}>
									<UserSmallCard
										title={link.user_title}
										label="&nbsp;"
										picture={`${Constants.BACKEND_URL}images/${link.user_image}`}
										theme='dark'
									/>
								</Link>
								{creator && !edit_page && !activity_is_Date_In_Past && (
									<StyledLink $isFullLink onClick={() => validate_user(link._id)}>Accepter</StyledLink>
								)}
							</div>
						)))}
				</UserListWrapper>

				<div>{activityUserLinkData.length}&nbsp;participant(s) (hors participants additionnels)</div>
				<UserListWrapper>
					{Array.isArray(activityUserLinkData) && (
						activityUserLinkData?.map((link) => (
							<div>
								<Link key={`user-${link.userId}`} to={`/user/${link.userId}`}>
									<UserSmallCard
										title={link.user_title}
										label={link.user_city}
										picture={`${Constants.BACKEND_URL}images/${link.user_image}`}
										theme='dark'
									/>
								</Link>
							</div>
						)))}
				</UserListWrapper>

				{Array.isArray(review_messages) && review_messages.length > 0 && (
					<div>{review_messages.length}&nbsp;avis sur l'expérience vécue</div>
				)}
				<ActivityWrapper>
					<CardsContainer>
						{Array.isArray(review_messages) && (
							review_messages?.map((review) => (
								<div>
									{review.userId !== activityData.userId && (
										<ReviewCard key={`msg-${review._id}`}
											content={review.comment}
											rating={review.rating}
											user_id={review.userId}
											user_title={review.user_title}
											user_image={`${Constants.BACKEND_URL}images/${review.user_image}`}
										/>
									)}
									{review.userId === activityData.userId && (
										<CreatorReviewCard key={`msg-${review._id}`}
											content={review.comment}
											rating={review.rating}
											target_userId={review.target_userId}
											target_user_title={review.target_user_title}
											target_user_image={`${Constants.BACKEND_URL}images/${review.target_user_image}`}
											user_id={review.userId}
											user_title={review.user_title}
											user_image={`${Constants.BACKEND_URL}images/${review.user_image}`}
										/>
									)}
									{review.userId === answers[32]._id && (
										<StyledLink $isFullLink onClick={() => edit_review(review._id)}>Modifier</StyledLink>
									)}
								</div>
							)))}
					</CardsContainer>
				</ActivityWrapper>

			</TopContainer>
		</div>
	)
}

export default Activity
